import React from "react"
import Layout from "../components/layout"
import "../components/layout.css"
import SEO from "../components/seo"
import AllStaff from "../images/all-staff.png"
import Staffimg from "../images/staff-img.png"
import { LazyLoadImage } from "react-lazy-load-image-component"

const VirtualAllStaff = () => (
	<Layout>
		<SEO
			title="Leadership Development Training - Professional Business Coach"
			description="Are you looking for leadership development training? Our professional business coaches help you sharpen your tool belt to bring your team together. Let us help today!"
		/>
		<div className="page-headline">
			<div className="container ">
				<div className="section-heading text-center">
					<h1 className="font-weight-bold text-uppercase flair">
						Virtual All Staff
					</h1>
				</div>
			</div>
			<div className="headline-bg" />
		</div>
		<div className="all-staff">
			<div className="container-fluid">
				<div className="row justify-content-between">
					<div className="col-md-6 p-md-5">
						<div className="mb-5">
							<div className="text-center p-5 virtual-staff">
								<h2>Virtual All-Staff</h2>
								<div className="w-20">
									<h4 className="py-2 text-white">(not the boring kind)</h4>
								</div>
								<LazyLoadImage
									className="leader-icon w-100"
									src={AllStaff}
									alt="All Staff"
								/>
							</div>
							<div className="mt-5">
								<h2 className="text-dark text-center">What You Get:</h2>
								<ul className="checks-ul">
									<li className="list-checks my-3">
										The playbook you need to create a disruptive and
										unexpectedly fun virtual event.
									</li>
									<li className="list-checks my-3">
										A unique blend of virtual and real-life activities to get
										your team up and moving.
									</li>
									<li className="list-checks my-3">
										Meal options that are delivered directly to each teammate’s
										door.
									</li>
									<li className="list-checks my-3">
										Challenge-based leagues to build camaraderie, teamwork, and
										keep people actively working towards a goal.
									</li>
									<li className="list-checks my-3">
										Leader training to help managers and supervisors inspire
										greatness during team breakouts.
									</li>
									<li className="list-checks my-3">
										Dynamic and interactive training time to level-up
										traditional topics.
									</li>
									<li className="list-checks my-3">
										A team of professional facilitators and virtual event
										specialists to run the behindthe-scenes effort.
									</li>
								</ul>
							</div>
							<div
								className="text-center"
								style={{ background: "#00c4cc", padding: "30px 0" }}
							>
								<h3 className="text-white">
									Walk through your playbook today:{" "}
									<a
										href="https://calendly.com/coachingforgood"
										className="text-white"
									>
										calendly.com/coachingforgood
									</a>
								</h3>
							</div>
						</div>
					</div>
					<div
						className="col-md-6 p-md-5 mt-5"
						style={{ background: "#4bc4cc" }}
					>
						<div className="text-center p-5 virtual-staff ">
							<h2>Unsure how to transition team events online?</h2>
							<h2 className="mt-3">
								Fearful of burnout if you schedule a multiday video meeting?
							</h2>
						</div>

						<div className="p-5 reinvent">
							<p>
								Reinvent your <br />
								team events <br />
								for the future <br />
								of work.
							</p>
						</div>
						<div>
							<LazyLoadImage
								className="leader-icon w-100"
								src={Staffimg}
								alt="Staff"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default VirtualAllStaff
